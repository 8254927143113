import "./NoLocationScreen.css";
import { StoreLocator } from "../components/StoreLocator/StoreLocator";
import { useSelector } from "react-redux";

export default function NoLocationScreen() {
  const referrer = useSelector((state) => state.scheduler.referrer);

  return (
    <div className="no-location">
      {referrer === "careplus" ? (
        <>
          <h1 className="heading">
            <p className="care-plus-heading">
              <span className="care-plus-brand">Care</span>
              <span className="care-plus-brand-plus">+</span> is still new, and
              not yet offered everywhere.
              <sup>*</sup>
              <br />
              Please select a shop here for a qualifying inspection today.
              <br />
              <p className="info-text">
                <sup>*</sup>Service plans and claims are honored universally
                while we rollout qualifying inspections and sales training to
                all shops.
              </p>
            </p>
          </h1>
        </>
      ) : (
        <h1 className="heading">
          Before you get started, you need to find a local shop.
        </h1>
      )}
      <div className={"store-locator"}>
        <StoreLocator />
      </div>
    </div>
  );
}
