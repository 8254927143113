import { StoreLocatorWidget } from "./StoreLocatorWidget.jsx";
import { useEffect, useState } from "react";

export const StoreLocator = () => {
  const [container, setContainer] = useState();

  function customObserver(_, observer) {
    let spButtons = document.querySelectorAll(".storepoint-cf-button");
    let mapMarkerBox = document.querySelectorAll("mapboxgl-popup-content");

    spButtons.forEach((elm) => {
      if (elm.textContent.includes("Set As Preferred Location")) {
        elm.remove();
      }

      if (elm.textContent.includes("Schedule Appointment")) {
        elm.setAttribute(
          "href",
          `${window.location.origin}/${elm.href.split("/")[3]}`
        );
        elm.style.background = "var(--brand-persimmon)";
        elm.children[0].style.setProperty("color", "white", "important");
      }
    });

    mapMarkerBox.forEach((elm) => {
      if (elm.textContent.includes("Set As Preferred Location")) {
        elm.setAttribute(
          "href",
          `${window.location.origin}?${elm.href.split("?")[1]}`
        );
      }
    });

    locationsClickHandler();

    if (spButtons.length > 0 && observer) {
      observer.disconnect();
    }
  }

  useEffect(() => {
    containerObserver();
    observeLocationSuggestions();

    new MutationObserver(customObserver).observe(document, {
      childList: true,
      subtree: true,
      characterData: true,
      attributes: true,
    });
  }, [container]);

  /**
   * Observer for container to fully load
   */
  function containerObserver() {
    let observer = new MutationObserver(function () {
      let containerElement = document.querySelector(
        "[id='storepoint-container']"
      );

      if (containerElement) {
        setContainer(containerElement);
        observer.disconnect();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
    });
  }

  /**
   * Observer for Location Suggestions from the search bar
   */
  function observeLocationSuggestions() {
    let observer = new MutationObserver(function () {
      let found = document.querySelectorAll(
        ".mapboxgl-ctrl-geocoder--suggestion"
      );

      if (found) {
        new MutationObserver(customObserver).observe(document.body, {
          childList: true,
          subtree: true,
          characterData: true,
          attributes: true,
        });
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });
  }

  /**
   * Listener for map marker click event.
   * Used to remove unwanted buttons from document.
   */
  document.addEventListener(
    "click",
    function (event) {
      // console.log(event.target);
      if (event.target.ariaLabel === "Map marker") {
        event.preventDefault();
        new MutationObserver(customObserver).observe(document, {
          childList: true,
          subtree: true,
          characterData: true,
          attributes: true,
        });
      }
    },
    false
  );

  /**
   * Listener for navigation items
   */
  const locationsClickHandler = () => {
    const locations = document.querySelectorAll("[data-cft='af6tp9lf6p']");
    locations.forEach((item) => {
      item.addEventListener("click", function (e) {
        e.preventDefault();
        const href = item.getAttribute("href");
        item.removeAttribute("href");
      });
    });

    const scheduleButtons = document.querySelectorAll(
      "[data-cft='ipshu9j3zn']"
    );
    scheduleButtons.forEach((item) => {
      item.addEventListener("click", function (e) {
        e.preventDefault();
        window.location = item.getAttribute("href");
      });
    });
  };

  return (
    <>
      <StoreLocatorWidget />
    </>
  );
};
